import { computed, ref, watch } from 'vue';
import { defineStore } from 'pinia';
import type { AccountDetail, RevenueGraphItem, BoardLocation, PhoneNumber, Options, Overview, GetAccountAssociationResponse } from '@apparatix/types/Account';
import { Activity } from '@apparatix/types/Activities';

export const useAccountStore = defineStore('account-store', () => {
  const accountDetails = ref<AccountDetail>();
  const accountOverview = ref<Overview>();
  const accountAssociations = ref<GetAccountAssociationResponse>();
  const accountActivities = ref<Activity[]>();

  const canOwnAgencies = ref(false);

  const options = ref<Options>();

  const revenueGraph = ref<RevenueGraphItem[]>();
  const currentLocations = ref<BoardLocation[]>();
  const futureLocations = ref<BoardLocation[]>();

  const workPhone = ref<PhoneNumber>({ addressType: 'Work', number: '' });
  const cellPhone = ref<PhoneNumber>({ addressType: 'Cell', number: '' });
  const faxPhone = ref<PhoneNumber>({ addressType: 'Fax', number: '' });

  const webPage = ref({ addressType: 'Work', url: '' });

  const address = ref({
    addressLine1: '',
    addressLine2: '',
    city: '',
    region: '',
    postalCode: '',
    fullAddress: '',
  });

  // Computed function that parses the user given url into one that can be used for a link
  const computedUrl = computed(() => {
    let url = webPage.value.url.trim();

    // Add 'https://' if missing
    if (!(/^https?:\/\//i).test(url)) {
      url = 'https://' + url;
    }

    return url;
  });

  const initializePhones = () => {
    if (!accountDetails.value) return;

    const phoneTypes = ['Work', 'Cell', 'Fax'];

    phoneTypes.forEach((type) => {
      let phone = accountDetails.value.phoneNumbers?.find(
        (phoneNumber) => phoneNumber.addressType === type,
      );

      if (!phone) {
        phone = {
          addressType: type,
          number: '',
        };
      }

      if (type === 'Work') {
        workPhone.value = phone;
      } else if (type === 'Cell') {
        cellPhone.value = phone;
      } else if (type === 'Fax') {
        faxPhone.value = phone;
      }

    });
  };

  const initializeWebPages = () => {
    if (!accountDetails.value) return;

    const urlTypes = ['Work'];

    urlTypes.forEach((type) => {
      let url = accountDetails.value.webPages?.find(
        (urlPage) => urlPage.addressType === type,
      );

      if (!url) {
        url = {
          addressType: type,
          url: '',
        };
      }

      if (type === 'Work') {
        webPage.value = url;
      }

    });
  };

  const initializeAddress = () => {
    if (!accountDetails.value) return;

    let addressLine = accountDetails.value.addresses[0];

    if (!addressLine) {
      addressLine = {
        addressLine1: '',
        addressLine2: '',
        city: '',
        region: '',
        postalCode: '',
        fullAddress: '',
      };
    }

    address.value = addressLine;
  };

  // Whenever we get an update to account details, refresh the split out variables in the store.
  watch(
    () => accountDetails.value,
    (newVal) => {
      if (newVal) {
        initializePhones();
        initializeWebPages();
        initializeAddress();
      }
    },
  );


  return {
    accountDetails,
    accountOverview,
    options,
    revenueGraph,
    currentLocations,
    futureLocations,
    workPhone,
    cellPhone,
    faxPhone,
    webPage,
    computedUrl,
    address,
    accountAssociations,
    accountActivities,
    canOwnAgencies,
  };
});
