import { compareAsc, format, formatDistanceToNowStrict, isBefore, parseISO } from 'date-fns';

export const useDateFns = () => {
  /**
   * Formats a raw ISO date into something more readable
   * @param {string | Date} date - Raw input date, expected to be in ISO format
   * @param {string} formatString - Format string to control how date is displayed
   * @see https://date-fns.org/v3.0.0/docs/format for documentation on format strings
   */
  const formatDate = (date: string | Date, formatString: string = 'yyyy-MM-dd', errorMessage = 'Invalid date'): string => {
    if(date instanceof Date) {
      return format(date, formatString);
    }

    try {
      return format(parseISO(date), formatString);
    } catch {
      return errorMessage;
    }
  };

  /**
   * Shows how long ago a date was relative to now. Eg "3 weeks ago", "1 year ago"
   * @param {string | Date} date - Raw input date, expected to be in ISO format
   */
  const formatDistance = (date: string | Date): string => {
    if(date instanceof Date) {
      return `${formatDistanceToNowStrict(date)} ago`;
    }

    try {
      return `${formatDistanceToNowStrict(parseISO(date))} ago`;
    } catch {
      return 'Invalid date';
    }
  };

  /**
   * Checks to see if a given date is in the past
   * @param date The date you want to determine is before now
   * @param exact If true, will compare to the exact minute and second. If false, will only consider the day before now before
   * @returns true if the provided date is before now
   */
  const isInThePast = (date: string | Date, exact: boolean = true): boolean => {
    try {
      const dateInstance = date instanceof Date ? date : parseISO(date);

      const now = new Date();
      if(exact) {
        return isBefore(dateInstance, now);
      }

      now.setHours(0, 0, 0, 0);
      return isBefore(dateInstance, now);
    } catch {
      console.error('Invalid date');
      return false;
    }
  };

  const parse = (date: string): Date => {
    try {
      return parseISO(date);
    } catch {
      return null;
    }
  };

  const areEqual = (a: string | Date, b: string | Date, nullIsEqual: boolean = true): boolean => {
    if(nullIsEqual && a === null && b === null) return true;
    if(!a || !b) return false;
    let aDate: Date, bDate: Date;
    if(typeof a === 'string') {
      aDate = parse(a);
    } else {
      aDate = a;
    }

    if(typeof b === 'string') {
      bDate = parse(b);
    } else {
      bDate = b;
    }

    return compareAsc(aDate, bDate) === 0;
  };

  /*
   * Date Formatting - https://date-fns.org/v3.0.0/docs/format
   */
  const getTimestampFormat = (): string => 'yyyyMMddHHmmss';

  return {
    areEqual,
    getTimestampFormat,
    formatDate,
    formatDistance,
    isInThePast,
    parse,
  };
};
