<script setup lang="ts">
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';

export interface ModalProps {
  visible: boolean;
  title: string;
  subtitle?: string;
  position?: 'center' | 'top' | 'bottom' | 'left' | 'right' | 'topleft' | 'topright' | 'bottomleft' | 'bottomright' | undefined;
  cancelLabel?: string;
  acceptLabel?: string;
  disableCancel?: boolean;
  disableAccept?: boolean;
  disableFooter?: boolean;
  canClickAccept?: boolean;
  canClickCancel?: boolean;
}

withDefaults(defineProps<ModalProps>(), {
  subtitle: '',
  position: 'top',
  cancelLabel: 'Cancel',
  acceptLabel: 'Ok',
  disableAccept: false,
  disableCancel: false,
  disableFooter: false,
  canClickAccept: true,
  canClickCancel: true,
});

const emit = defineEmits<{
  (e: 'on:accept'): void;
  (e: 'on:close'): void;
}>();

const onCancel = () => emit('on:close');
const onAccept = () => emit('on:accept');
</script>

<template>
  <Dialog
    :visible="visible"
    :position="position"
    modal
    :pt="{
      root: {
        style: {
          boxShadow: 'none',
          border: 'none',
          maxHeight: '100%',
        },
        class: 'w-screen h-screen lg:w-[95vw] lg:h-[95vh] xl:w-3/5 xl:h-3/4 !mt-0'
      }
    }"
  >
    <template #container>
      <div class="flex flex-col h-full w-full">
        <div class="w-full" :class="$style['header']">
          <slot name="header">
            <div class="flex items-center p-4 text-[var(--primary-text-blue)]">
              <div class="flex flex-col grow gap-2">
                <h1 class="text-base font-medium leading-none">
                  {{ title }}
                </h1>
                <p v-if="subtitle" class="leading-none text-sm">
                  {{ subtitle }}
                </p>
              </div>
              <div class="flex-none cursor-pointer" @click="emit('on:close')">
                <span class="pi pi-times" />
              </div>
            </div>
          </slot>
        </div>
        <div class="grow w-full overflow-y-auto">
          <slot name="body" />
        </div>
        <div v-if="!disableFooter" class="flex-none w-full lg:rounded-b-lg bg-[var(--white)] border-solid border-t border-[var(--secondary-border-color)]">
          <slot name="footer">
            <div class="w-full flex justify-end items-center gap-2 p-4">
              <Button
                v-if="!disableCancel"
                :label="cancelLabel"
                :disabled="!canClickCancel"
                size="small"
                severity="secondary"
                @click="onCancel"
              />
              <Button
                v-if="!disableAccept"
                :label="acceptLabel"
                :disabled="!canClickAccept"
                size="small"
                @click="onAccept"
              />
            </div>
          </slot>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<style module>
  .header {
    background: linear-gradient(0deg, rgba(227, 225, 255, 0.50) 0%, rgba(227, 225, 255, 0.50) 100%), #E3E1FF;
  }
</style>
