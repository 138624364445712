<script setup lang="ts">
import { useConfirm } from 'primevue/useconfirm';
import { useDialog } from 'primevue/usedialog';
import Button from 'primevue/button';
import Column from 'primevue/column';
import ConfirmPopup from 'primevue/confirmpopup';
import DataTable from 'primevue/datatable';
import DynamicDialog from 'primevue/dynamicdialog';
import FileUpload, { FileUploadUploaderEvent } from 'primevue/fileupload';
import ProgressSpinner from 'primevue/progressspinner';
import Tag from 'primevue/tag';
import Toast from 'primevue/toast';
import DigitalVendorEndpointForm from './DigitalVendorEndpointForm.vue';
import { useDateFns, useDigitalVendorEndpoint, useToastNotifications } from '@apparatix/composables';
import { DigitalVendorEndpointStore as store } from '../stores/DigitalVendorEndpointStore';
import { DigitalVendor, DigitalVendorEndpoint } from '@apparatix/types';

interface Props {
    sspList: Array<DigitalVendor>;
    endpointTypes: Array<{name: string, value: string}>;
}
const props = defineProps<Props>();

store.setVendors(props.sspList);
store.setAvailableEndpointTypes(props.endpointTypes);

const { deleteEndpoint, uploadFile } = useDigitalVendorEndpoint();
const { formatDate, formatDistance } = useDateFns();
const confirmDelete = useConfirm();
const editDialog = useDialog();
const { showSuccess, showError } = useToastNotifications();


const showNewOrEditModal = (vendor: DigitalVendor, endpoint: DigitalVendorEndpoint = null) => {
  if(!vendor) return;
  if(!endpoint) {
    store.setActiveEndpoint(store.getEmptyEndpointModel(vendor.id));
  } else {
    store.setActiveEndpoint(endpoint);
  }

  editDialog.open(DigitalVendorEndpointForm, {
    props: {
      header: endpoint === null ? 'Create new endpoint' : 'Edit endpoint',
      style: { width: '33vw' },
      modal: true,
      blockScroll: true,
      position: 'top',
    },
  });
};

const getTypeTagColor = (type: string) => {
  switch(type.toLowerCase()) {
  case 'authorization':
    return '';
  case 'ads':
    return 'success';
  case 'inventory':
    return 'info';
  case 'reporting':
    return 'warning';
  default:
    return 'info';
  }
};

const canDelete = (type: string) => type.toLowerCase() === 'reporting';

const onDeleteClick = (event: MouseEvent, endpoint: DigitalVendorEndpoint) => {
  confirmDelete.require({
    target: event.currentTarget as HTMLElement,
    message: 'Are you sure you want to delete this endpoint? This cannot be undone',
    header: 'Confirm delete',
    accept: () => handleDelete(endpoint),

    position: 'bottomleft',
    icon: 'pi pi-exclamation-triangle',
  });
};

const handleDelete = async (endpoint: DigitalVendorEndpoint) => {
  endpoint.isLoading = true;
  const result = await deleteEndpoint(endpoint.id);
  if(result) {
    store.removeEndpointFromList(endpoint);
  } else {
    endpoint.isLoading = false;
    showError('Error deleting endpoint');
  }
};

const onUpload = async (vendorId: number, event: FileUploadUploaderEvent) => {
  const result = await uploadFile(vendorId, event.files[0]);
  if(result) {
    showSuccess('File successfully uploaded');
  } else {
    showError('Error uploading file');
  }
};

const getDeleteButtonTooltipText = (type: string) => {
  if(type.toLowerCase() === 'reporting') return 'Endpoints of type reporting cannot be deleted';
  return 'Delete this endpoint';
};
</script>

<template>
  <section class="flex flex-col gap-y-8 w-1/2 mx-auto border-x border-slate-50 border-indigo-500">
    <div v-for="vendor in store.vendors" :key="vendor.display_name" class="table">
      <DataTable
        striped-rows
        :value="vendor.digital_vendor_endpoints"
        class="p-datatable-sm"
        :pt="{
          header: () => ({
            style: {
              'background-color': '#E8EAFF'
            }
          }),
          table: () => ({
            style: {
              'table-layout': 'fixed',
            },
          }),
        }"
      >
        <template #header>
          <div class="flex flex-nowrap items-center gap-2">
            <h1 class="grow text-xl text-3xl font-light">
              {{ vendor.display_name }}
            </h1>
            <FileUpload
              v-tooltip.left="'Upload backfill'"
              style="font-size: 11px"
              name="file"
              url="/digital-vendor/admin/import"
              mode="basic"
              accept=".csv"
              choose-label="Backfill"
              :auto="true"
              custom-upload
              @uploader="onUpload(vendor.id, $event)"
            />
            <Button
              v-tooltip.left="`Add a new Digital Vendor Endpoint to ${vendor.display_name}`"
              label="Add"
              icon="pi pi-plus"
              size="small"
              @click="showNewOrEditModal(vendor)"
            />
          </div>
        </template>
        <Column header="Type" style="width: 100px">
          <template #body="slotProps">
            <Tag :severity="getTypeTagColor(slotProps.data.type)" :value="slotProps.data.type" />
          </template>
        </Column>
        <Column header="Base URL" style="width: 100%">
          <template #body="slotProps">
            <a :href="slotProps.data.base_url" target="_new" class="hover:underline">{{ slotProps.data.base_url }}</a>
          </template>
        </Column>
        <Column header="Created" style="width: 150px">
          <template #body="slotProps">
            {{ formatDate(slotProps.data.created_at) }}
          </template>
        </Column>
        <Column header="Updated" style="width: 150px">
          <template #body="slotProps">
            {{ formatDistance(slotProps.data.updated_at) }}
          </template>
        </Column>
        <Column style="width: 80px">
          <template #body="slotProps">
            <Button
              v-tooltip.left="'Edit this endpoint'"
              size="small"
              severity="info"
              rounded
              outlined
              @click="showNewOrEditModal(vendor, slotProps.data)"
            >
              <i class="pi pi-file-edit" style="font-size: 1.2rem" />
            </Button>
          </template>
        </Column>
        <Column style="width: 80px">
          <template #body="slotProps">
            <!--
                The seemingly unnecessary wrapper div here is because some browsers don't emit events
                for elements that are disabled. Since the PrimeVue tooltip depends on 'onHover', the tooltip
                won't render if the button is disabled
            -->
            <div v-tooltip.left="getDeleteButtonTooltipText(slotProps.data.type)">
              <Button
                v-if="!slotProps.data.isLoading"
                size="small"
                severity="danger"
                :disabled="canDelete(slotProps.data.type)"
                rounded
                outlined
                @click="onDeleteClick($event, slotProps.data)"
              >
                <i class="pi pi-trash" style="font-size: 1.2rem" />
              </Button>
              <ProgressSpinner
                v-else
                style="width: 40px; height: 40px"
                stroke-width="2"
                animation-duration=".5s"
              />
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </section>
  <!-- TODO: move to app root -->
  <DynamicDialog />
  <ConfirmPopup />
  <Toast />
</template>
