export const useThrottle = (fn: (...args: any[]) => void, delay: number) => {
  let lastCall = 0;

  return (...args: any[]) => {
    const now = new Date().getTime();
    if(now - lastCall < delay) {
      return;
    }

    lastCall = now;
    fn(...args);
  };
};
