import { definePreset } from '@primevue/themes';
import Aura from '@primevue/themes/aura';

type ColorMode = 'light' | 'dark';
type ColorGroup = 'gray' | 'purple' | 'blue' | 'green' | 'orange' | 'red';
type ColorGradient = '50' | '75' | '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900' | '1000' | '1100' | '1200' | '1300';
type CssVar<TMode extends ColorMode, TGroup extends ColorGroup, TGradient extends ColorGradient> = `var(--apx-${TMode}-${TGroup}-${TGradient})`;

export type ApparatixPrimitiveColorToken<TMode extends ColorMode, TGroup extends ColorGroup> = {
  50?: TGroup extends 'gray' ? CssVar<TMode, TGroup, '50'> : never;
  75?: TGroup extends 'gray' ? CssVar<TMode, TGroup, '75'> : never;
  100: CssVar<TMode, TGroup, '100'>;
  200: CssVar<TMode, TGroup, '200'>;
  300: CssVar<TMode, TGroup, '300'>;
  400: CssVar<TMode, TGroup, '400'>;
  500: CssVar<TMode, TGroup, '500'>;
  600: CssVar<TMode, TGroup, '600'>;
  700: CssVar<TMode, TGroup, '700'>;
  800: CssVar<TMode, TGroup, '800'>;
  900: CssVar<TMode, TGroup, '900'>;
  1000?: TGroup extends 'purple' | 'blue' | 'green' | 'orange' | 'red' ? CssVar<TMode, TGroup, '1000'> : never;
  1100?: TGroup extends 'purple' | 'blue' | 'green' | 'orange' | 'red' ? CssVar<TMode, TGroup, '1100'> : never;
  1200?: TGroup extends 'purple' | 'blue' | 'green' | 'orange' | 'red' ? CssVar<TMode, TGroup, '1200'> : never;
  1300?: TGroup extends 'purple' | 'blue' | 'green' | 'orange' | 'red' ? CssVar<TMode, TGroup, '1300'> : never;
};

// interface ApparatixPrimitiveTokens {
//   color: {
//     light: {
//       gray: ApparatixPrimitiveColorToken<'light', 'gray'>;
//       purple: ApparatixPrimitiveColorToken<'light', 'purple'>;
//       blue: ApparatixPrimitiveColorToken<'light', 'blue'>;
//       green: ApparatixPrimitiveColorToken<'light', 'green'>;
//       orange: ApparatixPrimitiveColorToken<'light', 'orange'>;
//       red: ApparatixPrimitiveColorToken<'light', 'red'>;
//     },
//     dark: {
//       gray: ApparatixPrimitiveColorToken<'dark', 'gray'>;
//       purple: ApparatixPrimitiveColorToken<'dark', 'purple'>;
//       blue: ApparatixPrimitiveColorToken<'dark', 'blue'>;
//       green: ApparatixPrimitiveColorToken<'dark', 'green'>;
//       orange: ApparatixPrimitiveColorToken<'dark', 'orange'>;
//       red: ApparatixPrimitiveColorToken<'dark', 'red'>;
//     }
//   }
// }
interface ApparatixPrimitiveTokens {

}

const remapRem = (rem: number) => `${(rem * 14) / 16}rem`;

const primitiveTokens: ApparatixPrimitiveTokens = {
  apxLightGray: {
    50: 'var(--apx-light-gray-50)',
    75: 'var(--apx-light-gray-75)',
    100: 'var(--apx-light-gray-100)',
    200: 'var(--apx-light-gray-200)',
    300: 'var(--apx-light-gray-300)',
    400: 'var(--apx-light-gray-400)',
    500: 'var(--apx-light-gray-500)',
    600: 'var(--apx-light-gray-600)',
    700: 'var(--apx-light-gray-700)',
    800: 'var(--apx-light-gray-800)',
    900: 'var(--apx-light-gray-900)',
  },
  apxLightPurple: {
    100: 'var(--apx-light-purple-100)',
    200: 'var(--apx-light-purple-200)',
    300: 'var(--apx-light-purple-300)',
    400: 'var(--apx-light-purple-400)',
    500: 'var(--apx-light-purple-500)',
    600: 'var(--apx-light-purple-600)',
    700: 'var(--apx-light-purple-700)',
    800: 'var(--apx-light-purple-800)',
    900: 'var(--apx-light-purple-900)',
    1000: 'var(--apx-light-purple-1000)',
    1100: 'var(--apx-light-purple-1100)',
    1200: 'var(--apx-light-purple-1200)',
    1300: 'var(--apx-light-purple-1300)',
  },
  apxLightBlue: {
    100: 'var(--apx-light-blue-100)',
    200: 'var(--apx-light-blue-200)',
    300: 'var(--apx-light-blue-300)',
    400: 'var(--apx-light-blue-400)',
    500: 'var(--apx-light-blue-500)',
    600: 'var(--apx-light-blue-600)',
    700: 'var(--apx-light-blue-700)',
    800: 'var(--apx-light-blue-800)',
    900: 'var(--apx-light-blue-900)',
    1000: 'var(--apx-light-blue-1000)',
    1100: 'var(--apx-light-blue-1100)',
    1200: 'var(--apx-light-blue-1200)',
    1300: 'var(--apx-light-blue-1300)',
  },
};

export const Apparatix = definePreset(Aura, {
  // semantic: {
  //   formField: {
  //     paddingX: remapRem(0.75),
  //     paddingY: remapRem(0.5),
  //   },
  // },
});
