import { ref, watch } from 'vue';
import { defineStore } from 'pinia';
import type { ContactDetail, GetContactAssociationResponse, Note, Options } from '@apparatix/types/Contacts';
import { Activity } from '@apparatix/types/Activities';

export const useContactStore = defineStore('contact-store', () => {
  const contactDetails = ref<ContactDetail>();
  const contactAssociations = ref<GetContactAssociationResponse>();
  const contactActivities = ref<Activity[]>();

  const options = ref<Options>();
  const contactNote = ref<Note>();

  const address = ref({
    addressLine1: '',
    addressLine2: '',
    city: '',
    region: '',
    postalCode: '',
    fullAddress: '',
  });

  const initializeAddress = () => {
    if (!contactDetails.value) return;

    let addressLine = contactDetails.value.addresses[0];

    if (!addressLine) {
      addressLine = {
        addressLine1: '',
        addressLine2: '',
        city: '',
        region: '',
        postalCode: '',
        fullAddress: '',
      };
    }

    address.value = addressLine;
  };

  const initializeNote = () => {
    if (!contactDetails.value) return;

    let noteLine = contactDetails.value.notes[0];

    if (!noteLine) {
      noteLine = { note: '' };
    }

    contactNote.value = noteLine;
  };

  // Whenever we get an update to contact details, refresh the split out variables in the store.
  watch(
    () => contactDetails.value,
    (newVal) => {
      if (newVal) {
        initializeNote();
        initializeAddress();
      }
    },
  );


  return {
    contactDetails,
    contactAssociations,
    contactNote,
    options,
    address,
    contactActivities,
  };
});
