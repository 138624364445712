import axios from 'axios';

export const useDigitalVendorEndpoint = () => {
  const baseUrl = '/digital-vendor/admin';

  const deleteEndpoint = async (id: number): Promise<boolean> => {
    try {
      const result = await axios.delete(`${baseUrl}/${id}`);
      return !!result?.data?.success;
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  const saveOrUpdateModel = async<T>(model: T): Promise<T> => {
    try {
      const result = await axios.post(`${baseUrl}/create-endpoint`, model);
      if (result.data) {
        return result.data as T;
      }
      return null;

    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const uploadFile = async (vendorId: number, file: File): Promise<boolean> => {
    const fd = new FormData();
    fd.append('id', vendorId.toString());
    fd.append('file', file);

    try {
      const result = await axios.post(`${baseUrl}/import`, fd, { headers: { 'Content-Type': 'multipart/form-data' }});
      if (result?.data?.status === 'success') {
        return true;
      }
      return false;

    } catch (e) {
      console.error(e);
      return false;
    }
  };

  return {
    deleteEndpoint,
    saveOrUpdateModel,
    uploadFile,
  };
};
