import { computed, ref, onMounted, onUnmounted } from 'vue';
import { defineStore } from 'pinia';
import { type OpportunityType, type OpportunityBase, type Swimlane, type ViewFormat, OpportunityFilter, DataAccessibleByType, User, Office, Options, PayloadBase, OptionsUser, OpportunityDetail, AssociationContact, AssociationAccount } from '@apparatix/types/Leads';
import type { CreateAccountResponse } from '@apparatix/types/Account';
import { useEndpoint } from '@apparatix/composables';
import { Activity } from '@apparatix/types/Activities';

export const useOpportunityStore = defineStore('opportunity-store', () => {
  const swimlanes = ref<DataAccessibleByType<Swimlane[]>>({
    Opportunity: [],
    Lead: [],
  });
  const options = ref<DataAccessibleByType<Options>>({
    Opportunity: null,
    Lead: null,
  });
  const stateReady = ref<DataAccessibleByType<boolean>>({
    Opportunity: false,
    Lead: false,
  });
  const currentView = ref<OpportunityType>();
  const viewFormat = ref<DataAccessibleByType<ViewFormat>>({
    Opportunity: 'swimlanes',
    Lead: 'swimlanes',
  });
  const shouldShowDetail = ref<DataAccessibleByType<boolean>>({
    Opportunity: false,
    Lead: false,
  });
  const selectedOpportunity = ref<DataAccessibleByType<OpportunityDetail | null>>({
    Opportunity: null,
    Lead: null,
  });
  const opportunityActivities = ref<DataAccessibleByType<Activity[] | null>>({
    Opportunity: null,
    Lead: null,
  });
  const activeFilters = ref<DataAccessibleByType<OpportunityFilter[]>>({
    Opportunity: [],
    Lead: [],
  });
  const lastGlobalSearchQuery = ref<DataAccessibleByType<string>>({ Opportunity: '', Lead: '' });
  const usersWithOpportunities = ref<DataAccessibleByType<User[]>>({
    Opportunity: [],
    Lead: [],
  });

  const currentlySelectedOwnersForFilter = ref<DataAccessibleByType<User[]>>({
    Opportunity: [],
    Lead: [],
  });

  const currentlySelectedOfficesForProjections = ref<Office[]>([]);
  const currentlySelectedUsersForProjections = ref<OptionsUser[]>([]);

  const selectedVisibleLanes = ref<DataAccessibleByType<Swimlane[]>>({
    Opportunity: [],
    Lead: [],
  });

  // Variables prefixed with _ are "private" variables, and not intended to be directly manipulated outside of this file
  const _opportunities = ref<DataAccessibleByType<OpportunityBase[]>>({
    Opportunity: [],
    Lead: [],
  });

  const campaignContacts = ref<AssociationContact[]>();
  const campaignAccounts = ref<AssociationAccount[]>();

  const currentUser = ref<OptionsUser>();

  const isFullyLoaded = computed(() => stateReady.value['Opportunity'] && stateReady.value['Lead']);

  const isDetailLoading = computed<DataAccessibleByType<boolean>>(() => ({
    Opportunity: selectedOpportunity.value['Opportunity'] === null,
    Lead: selectedOpportunity.value['Lead'] === null,
  }));

  const applyFilters = (type: OpportunityType): OpportunityBase[] => activeFilters.value[type].reduce(
    (filtered, filter) => filtered.filter(filter.predicate),
    _opportunities.value[type],
  );

  const opportunities = computed<DataAccessibleByType<OpportunityBase[]>>(() => {
    let filteredOpportunities: OpportunityBase[];
    let filteredLeads: OpportunityBase[];

    const anyOpportunityFilters = activeFilters.value['Opportunity'].length !== 0;
    const anyLeadFilters = activeFilters.value['Lead'].length !== 0;

    if(anyOpportunityFilters) {
      filteredOpportunities = applyFilters('Opportunity');
    } else {
      filteredOpportunities = _opportunities.value['Opportunity'];
    }

    if(anyLeadFilters) {
      filteredLeads = applyFilters('Lead');
    } else {
      filteredLeads = _opportunities.value['Lead'];
    }

    return {
      Opportunity: filteredOpportunities,
      Lead: filteredLeads,
    };
  });

  const visibleSwimlanes = computed<DataAccessibleByType<Swimlane[]>>(() => ({
    Opportunity: swimlanes.value['Opportunity']
      .filter(lane => selectedVisibleLanes.value['Opportunity']
        .some(l => lane.id === l.id))
      .sort(swimlaneSortCb),
    Lead: swimlanes.value['Lead']
      .filter(lane => selectedVisibleLanes.value['Lead']
        .some(l => lane.id === l.id))
      .sort(swimlaneSortCb),
  }));

  const totalOpportunityValue = computed<DataAccessibleByType<number>>(() => ({
    Opportunity: opportunities.value['Opportunity']
      .filter(opportunity => opportunity.status !== 'Lost')?.reduce((acc, current) => acc + current.value, 0),
    Lead: opportunities.value['Lead']
      .filter(opportunity => opportunity.status !== 'Lost')?.reduce((acc, current) => acc + current.value, 0),
  }));

  const areOpportunitiesHiddenByFilter = (type: OpportunityType, laneId: number) => {
    if(activeFilters.value[type].length === 0) return false;

    const hasAnyUnfiltered = _opportunities.value[type].some(opportunity => opportunity.swimlaneId === laneId);
    const hasAnyFiltered = opportunities.value[type].some(opportunity => opportunity.swimlaneId === laneId);

    return !hasAnyFiltered && hasAnyUnfiltered;
  };

  const setOpportunities = (type: OpportunityType, newOpportunities: OpportunityBase[]) => {
    if(_opportunities.value[type].length === 0) {
      _opportunities.value[type] = newOpportunities;
    } else {
      console.warn('Discarded attempt to reset master list of opportunities after state has already been initialized. If you are attempting to filter the data, add your filter to activeFilters instead, and the data will automatically update');
    }
  };

  const addOpportunity = (type: OpportunityType, opportunity: OpportunityBase, highlight: boolean = true) => {
    if(highlight) {
      opportunity.justAddedToUi = true;
      setTimeout(() => {
        const opp = _opportunities.value[type].find(o => o.id === opportunity.id);
        if(opp) {
          opp.justAddedToUi = false;
        }
      }, 10000);
    }
    _opportunities.value[type].unshift(opportunity);
  };

  const removeOpportunity = (type: OpportunityType, id: number) => {
    const idx = _opportunities.value[type].findIndex(opportunity => opportunity.id === id);
    if(idx === -1) return;
    _opportunities.value[type].splice(idx, 1);
  };

  const updateOpportunity = (type: OpportunityType, opportunity: OpportunityBase) => {
    const idx = _opportunities.value[type].findIndex(o => o.id === opportunity.id);
    if(idx === -1) return;
    _opportunities.value[type][idx] = opportunity;
  };

  const swimlaneSortCb = (a: Swimlane, b: Swimlane) => {
    const aOrder = parseInt(a.order, 10);
    const bOrder = parseInt(b.order, 10);
    if(isNaN(aOrder) || isNaN(bOrder)) {
      throw new Error('Invalid value for lane sort field');
    }

    if(aOrder < bOrder) return -1;
    if(aOrder > bOrder) return 1;
    return 0;
  };

  const opportunityEndpoint = useEndpoint<PayloadBase<OpportunityBase>>({
    url: '/crm/opportunities',
    type: 'POST',
    successField: 'data',
    onSuccess: response => {
      _opportunities.value['Lead'].push(response.data.opportunity);
    },
    toast: { showOnSuccess: false },
  });


  const newOpportunityData = ref<PayloadBase<OpportunityBase>>();

  const accountEndpoint = useEndpoint<CreateAccountResponse>({
    url: '/crm/accounts',
    type: 'POST',
    successField: 'data',
    onSuccess: response => {
      const opportunity = newOpportunityData.value;
      opportunity.data.opportunity.accountId = response.data.account.c2_id;
      opportunityEndpoint.makeRequest(opportunity);
    },
    toast: { showOnSuccess: false },
  });

  const onLeadAddedFromMap = (e: CustomEvent) => {
    const accountData = { data: { account: e.detail.account }};

    const opportunityData = { data: { opportunity: e.detail.opportunity }};
    newOpportunityData.value = opportunityData;

    accountEndpoint.makeRequest(accountData);
  };

  onMounted(() => {
    window.addEventListener('lead-added-from-map', onLeadAddedFromMap);
  });

  onUnmounted(() => window.removeEventListener('lead-added-from-map', onLeadAddedFromMap));

  return {
    activeFilters,
    addOpportunity,
    areOpportunitiesHiddenByFilter,
    campaignAccounts,
    campaignContacts,
    currentlySelectedOwnersForFilter,
    currentlySelectedOfficesForProjections,
    currentlySelectedUsersForProjections,
    currentUser,
    currentView,
    isDetailLoading,
    isFullyLoaded,
    lastGlobalSearchQuery,
    opportunities,
    opportunityActivities,
    options,
    removeOpportunity,
    selectedOpportunity,
    selectedVisibleLanes,
    setOpportunities,
    shouldShowDetail,
    stateReady,
    swimlanes,
    totalOpportunityValue,
    updateOpportunity,
    usersWithOpportunities,
    viewFormat,
    visibleSwimlanes,
  };
});
